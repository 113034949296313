@import 'vars';
@import 'libs';

@import 'mixins';

@import 'menu';
@import 'wide-feature';




.feature-section {
  background: #EEF3FA;
  padding: 15px 0 70px 0;
  position: relative;
}

.top-wrapper {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.top-right {
  width: 450px;
  @include media-breakpoint-down(sm) {
    order: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}

.slider-pagination {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
    position: absolute;
    bottom: -3px !important;
    text-align: center;
    left: 0;
    width: 100%;
  }
}

.top-left {
  @include media-breakpoint-down(sm) {
    order: 2;
  }
}

.top-links {
  display: flex;
  align-items: center;
  margin-top: 20px;
  @include media-breakpoint-down(xs) {
    margin-top: 28px;
    flex-wrap: wrap;
  }
}

.link-obj {
  margin-right: 50px;
  @include media-breakpoint-down(xs) {
    margin-right: 0px;
    margin-top: 8px;
    order: 2;
    text-align: center;
    width: 100%;
  }
  a {
    padding: 9px 0 0 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .3px;
    border-bottom: 1px dashed #0074d3;
    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }
  }
}

.link-note {
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: rgb(136, 136, 136);
}

.images-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  @include media-breakpoint-down(sm) {
    // display: block;
    flex-wrap: nowrap;
    // height: 200px;
  }
}

.slide-wrapper {
  width: 47%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  // overflow: hidden;
  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
}

.top-img {
  @include media-breakpoint-down(sm) {
    width: 100%;
    height: auto;
  }
  img {
    display: block;
    max-width: 100%;
    border-radius: 3px;
    box-shadow: -10px 10px 32px rgba(0, 0, 0, 0.1);
    z-index: 999;
    height: auto;
    @include media-breakpoint-down(sm) {
      border-radius: 6px;
    }
  }
}

.top-title {
  font-size: 26px;
  font-weight: 800;
  margin: 0;
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 19px;
    font-weight: 800;
  }
}

.top-subtitle {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: rgb(44, 44, 44);
  @include media-breakpoint-down(sm) {
    font-size: 17px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 15px;
    font-weight: 700;
    color: rgb(77, 77, 77);
  }
}

.feat-list {
  margin: 25px 0 35px 0px;
  padding: 0;
  max-width: 530px;
  list-style: none;
  @include media-breakpoint-down(xs) {
    margin-top: 20px;
  }

  li {
    padding: 0;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    &:before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $brandblue;
      position: absolute;
      left: -15px;
      top: 10px;
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
}

.feat_title {
  font-weight: 600;
  font-size: 16px;
  @include media-breakpoint-down(xs) {
    font-size: 15px;
  }
}

.feat_text {
  display: inline-block;
  margin-top: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: rgb(44, 44, 44);
  @include media-breakpoint-down(xs) {
    font-size: 13px;
  }
}

.above-features {
  position: absolute;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  height: 55px;
  left: 0;
  right: 0;
  bottom: -35px;
  background: #fff;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 6px;
  box-shadow: -10px 10px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  @include media-breakpoint-down(sm) {
    position: relative;
    flex-direction: column;
    width: auto;
    height: auto;
  }
}

.above-feature {
  flex-basis: 30%;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xs) {
    flex-basis: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  &__icon {
    width: 55px;
    height: 55px;
    margin-right: 20px;
  }

  &__icon-img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  &__text {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
  }
}



.manual-wrapper {
  display: flex;
  margin: 0 -15px;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 18px;

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
  }
}

.manual-title {
  flex-basis: 40%;
  padding: 0 15px;
  box-sizing: border-box;
  border-right: 4px solid $brandorange;

  @include media-breakpoint-down(xs) {
    flex-basis: 100%;
    border-left: 4px solid $brandorange;
  }
}

.manual-subtitle {
  flex-basis: 55%;
  padding: 0 30px;
  box-sizing: border-box;
  color: #0e2041;
  font-weight: 500;
  line-height: 1.5;

  @include media-breakpoint-down(xs) {
    flex-basis: 100%;
    padding: 0 15px;
    line-height: 1.3;
    color: #495364;
  }
}

.steps-wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step-obj {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
  }

  &__photo {
    width: 250px;
    // overflow: hidden;
    margin-right: 30px;

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px;
      box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.25);
      overflow: hidden;
    }
  }

  &__content {
    // flex-basis: 80%;
    width: 60%;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  &__heading {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 6px;
    letter-spacing: -.3px;

    @include media-breakpoint-down(xs) {
      font-size: 18px;
      margin: 10px 0;
    }
  }

  &__desc {
    line-height: 1.3;
    font-weight: 400;
    font-size: 16px;

    @include media-breakpoint-down(xs) {
      font-size: 15px;
    }
  }

  &:first-child:before {
    @include media-breakpoint-down(xs) {
      position: relative;
    }
  }

  &:before {
    width: 10%;
    display: inline-block;
    counter-increment: step;
    content: counter(step);
    background: transparent;
    font-size: 56px;
    font-weight: 900;
    color: rgb(190, 190, 190);
    // width: 55px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    left: -80px;
    top: 0px;

    @include media-breakpoint-down(sm) {
      left: 10px;
    }

    @include media-breakpoint-down(xs) {
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      background: rgba(0, 0, 0, 0.397);
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
    }
  }

}

a {
  color: $brandblue;
  text-decoration: none;
  transition: .2s all;

  &:hover {
    color: $brandbluedark;
  }
}

.dual-section {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px 0;
  border-bottom: 1px solid #eeeeee;

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
  }
}

.dot {
  width: 50px;
  height: 1px;
  background: #000;
}

.left-side {
  width: 50%;
  padding: 25px 15px 15px 15px;
  box-sizing: border-box;
  background: #FF8989;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.left-side-content {
  margin-left: auto;
  width: 555px;
  max-width: 100%;
}

.warning-content {
  font-weight: 500;

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 15px;

    li {
      margin-bottom: 5px;

      &:before {
        content: '—';
        display: inline-block;
      }
    }
  }
}

.right-side {
  width: 50%;
  padding: 25px 15px 15px 15px;
  box-sizing: border-box;
  background: rgb(255, 255, 255);

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.right-side-content {
  width: 555px;
  margin-right: auto;
  max-width: 100%;
}

.complect-info {
  font-weight: 500;

  p {}

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 15px;

    li {
      margin-bottom: 5px;

      &:before {
        content: '—';
        display: inline-block;
      }
    }
  }
}

.anchor-link {
  border-bottom: 1px dashed #0074d3;

  &--warning {
    color: red;
    border-color: red;
  }
}

mark {
  background: $brandblue;
  color: #fff;
  padding: 2px;
}

.section-div {
  padding: 40px 0;
  // border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}


.dark-bg {
  background: #eef3fa;
  padding: 30px 0;
}

.white-bg {
  background: #fff;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.columns {
  width: 100%;
  margin: 25px -15px 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

.feature-object {
  padding: 0 15px;
  box-sizing: border-box;
  color: #0e2041;
  width: 50%;
  margin-bottom: 35px;
  position: relative;

  &__icon {
    opacity: .8;
    width: 50px;
    height: 50px;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 32%;
    background: #fff;
    box-shadow: rgba(62, 57, 107, 0.28) -10px 10px 32px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }

  &__icon-img {
    max-width: 50px;
    height: auto;
  }

  &__heading {
    font-size: 19px;
    font-weight: 700;
  }

  &__desc {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
    line-height: 1.3;
    letter-spacing: -.2px;
  }

  @include media-breakpoint-down(xs) {
    flex-basis: 100%;
    padding: 0 10px;
  }
}

.return-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
  }
}



.return-title {
  padding: 0 15px;
  box-sizing: border-box;
  flex-basis: 20%;
  // margin-right: auto;
  font-size: 22px;
  font-weight: 800;
  border-right: 4px solid $brandorange;

  @include media-breakpoint-down(xs) {
    flex-basis: 100%;
    border-left: 4px solid $brandorange;
    margin-bottom: 20px;
  }
}

.return-block {
  flex-basis: 20%;
  padding: 0 15px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  color: #0e2041;

  @include media-breakpoint-down(xs) {
    flex-basis: auto;
    margin-bottom: 18px;
  }
}


.profit-wrapper {
  display: flex;
  margin: 0 -15px;
  align-items: center;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.profit-title {
  font-size: 20px;
  font-weight: 800;
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  flex-basis: 22%;
  border-right: 4px solid $brandorange;
  @include media-breakpoint-down(sm) {
    flex-basis: 100%;
    border-left: 4px solid $brandorange;
    margin-bottom: 25px;
  }
}

.profit-block {
  flex-basis: 20%;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    flex-basis: 50%;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}


.inline-icon {
  display: inline-block;
  width: 30px;
  position: relative;

  &-img {
    position: relative;
    width: 100%;
    height: auto;
    top: 5px;
    left: 10px;
  }
}


@import 'mixins';

@import 'btn';

@import 'headers';

body {
  font-family: 'Montserrat', sans-serif;
  counter-reset: step;
  scroll-behavior: smooth;
}

.container {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
}

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}









.features-wrapper {
  display: flex;
  // border: 6px solid #2c30f7;
  border: 6px solid $brandblue;
  padding: 15px 0px;
  // margin: 0 -15px;
  flex-wrap: wrap;
}

.feature {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 25px;

  &--offer {
    width: 100%;
    padding: 0;
    color: #fff;
  }

  &__icon {
    flex-basis: 20%;
    text-align: center;

    &--offer {
      flex-basis: 14%;
    }
  }

  &__icon-img {
    max-width: 45px;
    height: auto;
  }

  &__content {
    flex-basis: 80%;
  }

  &__heading {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 7px;

    &--offer {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 7px;
    }
  }

  &__description {
    font-size: 14px;
    font-weight: 500;

    &--offer {
      font-size: 15px;
      font-weight: 500;
    }
  }
}



// Секция листинга товаров

.goods-wrapper {
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-top: 15px;
}

.product-wrapper {
  width: 25%;
  height: 420px;
  padding: 0 10px;
  margin-bottom: 20px;
  box-sizing: border-box;

  @include media-breakpoint-down(sm) {
    width: 33%;
    padding: 0 8px;
  }

  @include media-breakpoint-down(xs) {
    width: 50%;
    height: 305px;
    padding: 0 5px;
  }
}

.product {
  height: 100%;
  padding: 0 15px 10px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.219);
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  position: relative;
  transition: .2s all;
  background: #fff;

  @include media-breakpoint-down(xs) {
    padding: 0 10px 7px 10px;
  }

  &:hover {
    border: 1px solid $brandorange;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &__image {
    margin: 0 -15px;
    border-bottom: 1px solid #e2e2e2;

    @include media-breakpoint-down(xs) {
      margin: 0 -10px;
    }

  }

  &__image-img {
    max-width: 100%;
    height: auto;
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 8px;
    margin-top: 8px;
    color: rgb(32, 32, 32);
    &:hover {
      color: #00559B;
      text-decoration: underline;
    }
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__price {
    font-weight: 600;
    margin-bottom: 12px;
  }

  &__links {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    // margin-top: 8px;
    width: 100%;
    text-align: center;
  }

  &__link {
    font-weight: 500;
    font-size: 14px;
  }

  &__badge {
    position: absolute;
    top: 10px;
    right: -4px;
    background: $brandorange;
    font-weight: 700;
    font-size: 14px;
    padding: 4px 8px;
    color: #fff;
  }
}

.price-value {
  font-weight: 700;
  // font-family: 'Segoe UI', serif;
  font-size: 24px;
  letter-spacing: -.3px;
}

.price-currency {
  font-size: 18px;
  font-weight: 600;
  color: rgb(83, 83, 83);
}

.product-data {
  display: block;
  width: 100%;
  margin-bottom: 3px;

  &__name {
    display: inline-block;
    width: 70%;
    font-size: 13px;
    font-weight: 400;
  }

  &__value {
    display: inline-block;
    text-align: left;
    width: 10%;
    font-weight: 500;
    font-size: 15px;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
}


// How to 

.how-to-section {
  margin: 15px -15px;
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.step {
  padding: 0 15px;
  flex-basis: 30%;
  position: relative;
  box-sizing: border-box;

  @include media-breakpoint-down(sm) {
    width: 50%;
    flex-basis: 50%;
    margin-bottom: 15px;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 15px;
  }

  &__photo {}

  &__photo-img {
    max-width: 100%;
    height: auto;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.219);
  }

  &__heading {
    margin-top: 6px;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: -.3px;

    @include media-breakpoint-down(xs) {
      margin-top: 2px;
      font-size: 16px;
    }
  }

  &:before {
    display: inline-block;
    position: absolute;
    // content: '1';
    counter-increment: step;
    content: counter(step) ".";
    background: #000;
    font-size: 36px;
    font-weight: 800;
    color: #fff;
    width: 55px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    left: -10px;
    top: 0px;

    @include media-breakpoint-down(sm) {
      left: 10px;
    }

    @include media-breakpoint-down(xs) {
      left: 15px;
    }
  }
}

.step-connect {
  width: 2%;
  background: rgba(0, 0, 0, 0.349);
  height: 2px;
  align-self: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}


// Features\for clients

.organizations {
  margin: 15px -15px 0 -15px;
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.org-feature {
  width: 25%;
  padding: 0 15px;
  box-sizing: border-box;

  @include media-breakpoint-down(sm) {
    width: 50%;
    margin-bottom: 20px;
  }

  &__icon {
    text-align: center;
    height: 80px;
  }

  &__icon-img {
    max-width: 60px;
    height: auto;

    &--smaller {
      max-width: 55px;
    }
  }

  &__heading {
    font-weight: 700;
    margin-bottom: 8px;

    // text-align: center;
    @include media-breakpoint-down(xs) {
      font-size: 15px;
    }
  }

  &__desc {
    font-size: 15px;
    font-weight: 500;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }
  }
}


// Warnings 

.warning-section {
  border: 4px solid #dc3535;
  width: 50%;
  padding: 5px 10px 10px 10px;
  font-weight: 500;
  align-self: flex-start;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  p {}

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 15px;

    li {
      margin-bottom: 5px;

      &:before {
        content: '—';
        display: inline-block;
      }
    }
  }
}





.col-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px 0 0 0;
  padding: 0;
  color: #fff;
  justify-content: space-between;

  li {
    width: 48%;
    height: 90px;
    font-weight: 500;
  }
}


.faq-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px -15px 0 -15px;
}

.faq-item {
  width: 50%;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 25px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &__question {
    display: inline-block;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 4px;
    position: relative;
    transition: .2s all;

    &:hover {
      color: $brandblue;
    }

    &:before {
      content: '';
      height: 20px;
      width: 1px;
      background: $brandorange;
      display: inline-block;
      position: absolute;
      left: -9px;
      font-weight: 400;
      font-size: 14.5px;

      @include media-breakpoint-down(xs) {
        position: relative;
        left: 0;
      }
    }
  }

  &__answer {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.35;

    // display: none;
    p {
      margin: 0 0 8px 0;
    }
  }
}


.sorting {
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;
}

.sorting-block {
  margin: 10px 0;
  overflow-x: auto;

  &__name {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 6px;
  }

  @include media-breakpoint-down(xs) {
    margin: 0px 0;
  }
}

.sorting-block__vars {
  font-size: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  @include media-breakpoint-down(xs) {
    margin: 10px 0;
    flex-wrap: nowrap;
  }

  a {
    position: relative;
    padding: 4px 8px;
    line-height: 1;
    color: #000;
    font-weight: 500;
    letter-spacing: -.3px;
    border-radius: 3px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $brandblue;
    }

    @include media-breakpoint-down(xs) {
      display: inline-block;
      margin-bottom: 3px;
      padding: 4px 8px;
      flex-shrink: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    font-size: 13px;
  }
}

a.active-tab {
  border-radius: 0;
  color: $brandblue;
  border-bottom: 2px solid $brandblue;
}


.blue-bg {
  height: 400px;
  background: #e2e2e2;
  position: relative;
}

.slide {
  display: flex;
  width: 100%;
}



.slider-photo {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  // background-image: url('../img/offer4.jpg')
}


.note {
  background: #eeeeee;
  font-weight: 500;
  font-size: 14px;
  padding: 15px;
  color: #6b6b6b;
}


// Footer 

.main-footer {
  background: #e2e2e2;
  padding: 30px 0;
}

.shop-info {
  padding: 0 15px;
  width: 25%;
  box-sizing: border-box;

  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.payments {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.payment-icon {
  width: 50px;
  padding: 0 10px;
  margin-bottom: 10px;
}

.payment-icon-img {
  max-width: 100%;
  height: auto;
}

.clients-info {
  padding: 0 15px;
  width: 25%;
  box-sizing: border-box;

  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.contacts-info-wrapper {
  width: 50%;
  padding: 0 15px;
  box-sizing: border-box;

  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.contact-info {
  margin-top: 15px;
}

.worktime {
  margin-top: 15px;
}


.info-name {
  display: block;
  font-weight: 600;
  margin-bottom: 6px;
} 

.info-value {
  font-size: 14px;
}

.logo-footer {
  max-width: 250px;
  height: auto;
}

.for-clients-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 500;

  li {
    margin-bottom: 4px;
    cursor: pointer;
  }
}

.bottom-text {
  margin-top: 30px;
  padding: 15px;
  color: #6b6b6b;
  font-size: 15px;
}

@import 'modal';
@import 'js';
@import 'fslightbox.min';

.features-section {
  padding-top: 70px;
  @include media-breakpoint-down(sm) {
    padding-top: 40px;
  }
}

#return-second-btn {
  color: #0074d3;
  cursor: pointer;
  transition: .2s all;
  &:hover {
    color: #00437A;
  }
}