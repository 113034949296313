.wide-features-wrapper {
  display: flex;
  flex-direction: column;
}

.wide-feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-bottom: 20px;
  // border-right: 1px solid $brandorange;
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-bottom: 25px;
  }
  @include media-breakpoint-down(xs) {
    // flex-direction: column;
  }
  &:nth-child(even) {
    align-self: flex-end;
    .wide-feature__photo {
      order: 1;
      margin-right: 50px;
      @include media-breakpoint-down(xs) {
        margin-right: 13px;
      }
    }
    .wide-feature__content {
      order: 2;
    }
  }
  &__content {
    flex-basis: 75%;
    @include media-breakpoint-down(xs) {
      // order: 2;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 6px;
    @include media-breakpoint-down(xs) {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
    }
  }
  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }
  }
  &__photo {
    flex-basis: 18%;
    border-radius: 50%;
    overflow: hidden;
    box-shadow:rgba(62, 57, 107, 0.28) -10px 10px 32px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    @include media-breakpoint-down(xs) {
      // order: 1;
      // width: 25%;
    }
  }
  &__photo-img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}