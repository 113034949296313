.js-open {
  display: block;
}


.back_to_top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #5492c5;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 2px;
  display: none;
}

.back_to_top:hover {
  background: $brandbluedark;
  color: #fff;
}

.back_to_top-show {
  display: block;
}