@import 'vars';

.btn {
  display: inline-block;
  font-weight: 600;
  letter-spacing: .3px;
  background: $brandblue;
  border-radius: 2px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  padding: 7px 18px;
  border: 1px solid transparent;
  transition: color, background-color .2s linear;
  box-shadow: 0 0 4px 0px #0074d381;
  &:hover {
    background: $brandbluedarker;
    color: #fff;
  }
  &:active {
    transform: translateY(1px);
  }
  &--outline {
    box-sizing: content-box;
    background: transparent;
    border: 1px solid $brandorange;
    color: #000;
    &:hover {
      background: $brandorange;
      color: #fff;
    }
  }
}



.shine-btn {
  z-index: 1;
  position: relative;
  color: #fff;
  font-weight: 600;
  letter-spacing: -.3px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  padding: 4px 16px;
  background: #fff;
  border: 3px solid $brandblue;
  border-radius: 2px;
  cursor: pointer;
  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: -3px;
    bottom: 0;
    left: 0;
    transform-origin: center left;
    transform: scaleX(0);
    transition: all .2s;
    background: $brandblue;
    color: #fff;
  }
  &:hover {
    color: #fff;
  }
  &:hover:before {
    // transform-origin: center left;
    transform: scaleX(1);
  }
  @include media-breakpoint-down(xs) {
    font-size: 11px;
    border-width: 1px;
    // align-self: flex-end;
    margin-top: 3px;
  }
}


.btn-top {
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  background: $brandblue;
  padding: 9px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  transition: color, background-color .2s linear;
  &:hover {
    background: $brandbluedarker;
    color: #fff;
  }
  &:active {
    transform: translateY(1px);
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
    text-align: center;
  } 
}