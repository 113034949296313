.menu {
  background: $brandbluedarker;
  padding: 12px 0;
  min-height: 25px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    display: none;
  }
  li {
    display: inline-block;
    margin-right: 8px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      width: 100%;
    }
  }
  a {
    display: block;
    color: #fff;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 15px;
    &:hover {
      background: rgba(255, 255, 255, 0.377);
      color: #fff;
    }
    @include media-breakpoint-down(sm) {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
}

.menu-list--open {
  @include media-breakpoint-down(sm) {
    display: flex;
    position: absolute;
    right: 15px;
    top: 30px;
    background: $brandblue;
    z-index: 999;
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.3);
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(sm) {
    position: relative;
  }
  @include media-breakpoint-down(xs) {
    justify-content: space-between;
  }
}

.logo-item {
  font-size: 17px;
  color: rgb(228, 228, 228);
  font-weight: 900;
  align-self: center;
  margin-right: auto;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xs) {
    font-size: 14px;
    font-weight: 800;
  }
  &:hover {
    color: rgb(197, 197, 197);
    .logo-item-img {
      transform: scale(1.12);
  }
  } 

}

.logo-item-img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  transition: all .1s linear;
}


.hamburger {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 25px;
  z-index: 101;
  cursor: pointer;
  display: none;
  transition: all .15s linear;
  @include media-breakpoint-down(sm) {
    display: flex;
    
  }
  div {
    width: 100%;
    height: 2px;
    background: rgb(255, 255, 255);
    transition: all .18s linear;
  }
  div:nth-child(2) {
    width: 17px;
    align-self: flex-end;
  }
  div:nth-child(3) {
    width: 13px;
    align-self: flex-end;
  }
}

.hamburger-is-open {
  transform: translateX(-10px) translateY(5px);
  div {
    background: #ffffff;
  }
  div:nth-child(1) {
    transform: rotate(-45deg) translateY(5px) translateX(-5px);
  }
  div:nth-child(2) {
    opacity: 0;
  }
  div:nth-child(3) {
    transform: rotate(45deg) translateY(-8px) translateX(-8px);
    width: 100%;
  }
}