.h1 {
  font-size: 25px;
  font-weight: 800;
}

.h2 {
  font-size: 22px;
  font-weight: 500;
}

.h3 {
  font-size: 20px;
  font-weight: 500;
}

.h4 {
  font-size: 18px;
  font-weight: 600;
}

.section-title {
  font-size: 26px;
  font-weight: 800;
  margin: 14px 0;
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 20px;
  }
}

.section-line {
  background: #ee6308;
  height: 3px;
  width: 50px;
  &--danger {
    background: #dc3535;
  }
}

.ending-section-line {
  background: #ee6308;
  height: 3px;
  width: 75px;
  margin: 0 auto;
}


.title-group-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.featured-title {
  display: inline-block;
  font-size: 26px;
  margin: 14px 0;
  font-weight: 800;
  color: rgb(15, 15, 15);
  @include media-breakpoint-down(xs) {
    font-size: 20px;
    margin: 12px 0 6px 0;
  }
  &--orange {
    background: $brandorange;
  }
}

.clean-header {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
}