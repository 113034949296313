 /* The Modal (background) */
 .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}




.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} 

 /* Modal Header */
 .modal-header {
  padding: 10px 16px;
  background-color: $brandblue;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  font-size: 22px;
  font-weight: 600;
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
  line-height: 1.4;
  max-height: 350px;
  overflow-y: scroll ;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 20px auto;
  padding: 0;
  border: 1px solid #888;
  width: 60%;
  max-width: 700px;
  max-height: 400px;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  @include media-breakpoint-down(sm) {
    width: 70%;
  }
  @include media-breakpoint-down(xs) {
    width: 90%;
  }
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
} 

/* The Close Button */
.close {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 25px;
  z-index: 101;
  cursor: pointer;
  // display: none;
  transition: all .15s linear;
  div {
    width: 100%;
    height: 2px;
    background: rgb(255, 255, 255);
    transition: all .18s linear;
  }
  div:nth-child(2) {
    width: 17px;
    align-self: flex-end;
  }
  div:nth-child(3) {
    width: 13px;
    align-self: flex-end;
  }
  transform: translateX(-10px) translateY(5px);
  div {
    background: #ffffff;
  }
  div:nth-child(1) {
    transform: rotate(-45deg) translateY(5px) translateX(-5px);
  }
  div:nth-child(2) {
    opacity: 0;
  }
  div:nth-child(3) {
    transform: rotate(45deg) translateY(-8px) translateX(-8px);
    width: 100%;
  }
}